import React, {ReactElement, useEffect, useRef, useState} from 'react';
import {DrupalNode} from "next-drupal";
import Image from "next/image";
import {RadioLiveSkeleton} from "@/components/programmation/index";
import {PlayerState} from "@/components/ui/player-state";
import {Button} from "@/components/ui/button";
import {AudioPlayerButton} from "@/components/audioPlayer";
import {IconPlayerPlayFilled} from "@tabler/icons-react";
import {absoluteUrl, dateDiff, radioLiveEmissionIsIncoming} from "@/lib/utils";
import {
  Carousel,
  CarouselContent,
  CarouselItem, CarouselNext, CarouselPrevious,
} from "@/components/ui/carousel"
import Container from "@/components/ui/container";
import Link from "next/link";
import { DateTime } from "luxon";

const ProgrammationRadioLive = ({titleWithMargin = true}): ReactElement => {
  const [emissionsRaw, setEmissionsRaw] = useState<DrupalNode[]>([])
  const [emissions, setEmissions] = useState<DrupalNode[]>([])
  const [isLoading, setLoading] = useState(true)
  const [currentDate, setCurrentDate] = useState(DateTime.local().setZone("Pacific/Noumea"))

  const emissionsRawRef = useRef<DrupalNode[]>(emissionsRaw)
  emissionsRawRef.current = emissionsRaw

  const fetchRadioLive = () => {
    fetch(process.env.NEXT_PUBLIC_DRUPAL_BASE_URL + '/radio-live-view/now?' + new URLSearchParams({
      field_heure_end: 'now'
    }))
      .then((res) => res.json())
      .then((data) => {
        setEmissionsRaw(data)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  const clearData = () => {
    const newDate = DateTime.local().setZone("Pacific/Noumea");
    setCurrentDate(newDate);
    let clearedData: DrupalNode[] = [];
    emissionsRawRef.current.map((item, index) => {
      if (radioLiveEmissionIsIncoming(item, newDate)) {
        item.dateDebut = new Date()
        item.dateDebut.setHours(item.heure_debut)
        item.dateDebut.setMinutes(item.minute_debut)
        clearedData.push(item)
      }
    })
    setEmissions(clearedData.slice(0, 6))
  }

  useEffect(() => {
    fetchRadioLive()
    const dataInterval = setInterval(fetchRadioLive, 3600000);
    const displayInterval = setInterval(clearData, 60000);
    return () => {
      clearInterval(dataInterval)
      clearInterval(displayInterval)
    }
  }, []);

  useEffect(() => {
    clearData()
  }, [emissionsRaw]);

  return (
    <div className="relative">
      {titleWithMargin ?
        <Container>
          <h2 className="text-[32px] text-foreground-dark mb-8">Radio live</h2>
        </Container> :
        <h2 className="text-[32px] text-foreground-dark mb-8">Radio live</h2>
      }

      {
        !isLoading && emissions ?
          (
            <Carousel
              opts={{
                align: "center",
                dragFree: true,
              }}
            >
              <div className="w-full carousel-gradient-r carousel-gradient-l h-[475px] overflow-hidden">

                <div className="h-full absolute flex items-center z-10 left-16 scale-125 max-md:hidden">
                  <CarouselPrevious/>
                </div>
                <div className="h-full absolute flex items-center z-10 right-16 scale-125 max-md:hidden">
                  <CarouselNext/>
                </div>

                <div className="p-slider z-0">
                  <CarouselContent overflowHidden={false}>
                    {emissions.map((emission, index) =>
                      (
                        <CarouselItem key={emission.nid}
                                      className={(index === 0 ? "basis-4/5 md:basis-1/2 lg:basis-1/3" : "basis-4/5 md:basis-1/3 lg:basis-1/4") + " group"}>
                          <div
                            className="relative h-[350px] transition-all group-hover:h-[400px]">
                            <Image src={absoluteUrl(emission.field_media_image)} className='h-full w-full object-cover ' alt="todo"
                                   width={300}
                                   height={350}/>

                            <div
                              className="absolute inset-0 p-[18px] flex flex-col justify-between group-hover:bg-gradient-to-t from-black/80 to-black/20">

                              {index === 0 ? (
                                <PlayerState isOnAir>En direct</PlayerState>
                              ) : (
                                <PlayerState>
                                  Commence
                                  dans {dateDiff(emission.dateDebut, currentDate).minutes < 59 ? dateDiff(emission.dateDebut, currentDate).minutes + ' minute(s)' : dateDiff(emission.dateDebut, currentDate).hours + ' heure(s)'}
                                </PlayerState>
                              )}

                              <div>
                                <div className="opacity-0 group-hover:opacity-100 text-white mb-4 transition-opacity">
                                  <h3 className="text-[32px]">
                                    <Link href={emission.link}>
                                    {emission.title}
                                    </Link>
                                    </h3>
                                  <span className="text-sm">avec {emission.field_animateurs}</span>
                                </div>

                                {index === 0 && (
                                  <>
                                    <Button size="iconPlayerLive" className="group-hover:hidden" asChild
                                            iconLeft={<IconPlayerPlayFilled size='20'/>}>
                                      <AudioPlayerButton>
                                        <span className="hidden md:inline">Écouter</span>
                                      </AudioPlayerButton>
                                    </Button>

                                    <Button className="hidden group-hover:flex" variant={"secondary"} asChild
                                            iconLeft={<IconPlayerPlayFilled size='20'/>}>
                                      <AudioPlayerButton>
                                        <span className="hidden sm:inline">Écouter</span>
                                      </AudioPlayerButton>
                                    </Button>
                                  </>
                                )}
                              </div>

                            </div>
                          </div>
                          <div className="group-hover:hidden">
                            <h3 className="text-[32px] text-foreground-dark">{emission.title}</h3>
                            <span className="text-sm">avec {emission.field_animateurs}</span>
                          </div>
                        </CarouselItem>
                      ))
                    }

                    <CarouselItem className="basis-4/5 md:basis-1/3 lg:basis-1/4">
                      <div
                        className="text-white bg-primary p-[18px] h-[350px] hover:h-[400px] transition-all relative flex flex-col justify-end">
                        <Link href="/programmation" className="stretched-link"><h3 className="text-[32px]">Voir la
                          grille
                          des programmes</h3></Link>
                      </div>
                    </CarouselItem>

                  </CarouselContent>
                </div>

              </div>
            </Carousel>
          ) : (
            <Container>
              <RadioLiveSkeleton/>
            </Container>
          )
      }

    </div>
  );
};

export default ProgrammationRadioLive;
