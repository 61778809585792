import {DrupalNode} from "next-drupal"
import {Carousel, CarouselContent, CarouselItem, type CarouselApi} from "@/components/ui/carousel";
import React, {useState} from "react";
import Image from "next/image";
import Link from "next/link";
import {absoluteUrl} from "@/lib/utils";

interface NodePodcastPromotedProps {
  favorites: DrupalNode[]
}

export function NodeFavoriteSlider({favorites}: NodePodcastPromotedProps) {
  const [api, setApi] = React.useState<CarouselApi>()
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  React.useEffect(() => {
    if (!api) {
      return
    }

    api.on("select", () => {
      if (!api) return;
      setIsStart(!api.canScrollPrev())
      setIsEnd(!api.canScrollNext())
    })
  }, [api])

  const Renderer = ({favorite}) => {
    return (
      <Link href={favorite.field_lien?.uri || ''} target="_blank">
        <div className="relative">
          <div>
            <Image className="w-full aspect-square object-cover"
                   src={absoluteUrl(favorite.field_media_illustration.field_media_image.uri.url)}
                   width="303"
                   height="303"
                   alt={favorite.field_media_illustration.field_media_image.resourceIdObjMeta.alt ? favorite.field_media_illustration.field_media_image.resourceIdObjMeta.alt : ''}
            />
          </div>
          <div className="absolute p-4 w-full bottom-0 bg-gradient-to-t from-black/80 to-black/0">
            <h1 className="mb-1 text-[20px] text-white">{favorite.title}</h1>
            {
              favorite.field_soustitre && (
                <p className="text-md text-neutral-200 mb-1">{favorite.field_soustitre}</p>
              )
            }
            <p className="mt-3">
              <span className="text-[14px] bg-white p-2">#{favorite.field_categorie.name}</span>
            </p>
          </div>
        </div>
      </Link>
    )
  }

  return (
    <Carousel
      setApi={setApi}
      className={(!isStart ? "carousel-gradient-l" : "") + " " + (!isEnd ? "carousel-gradient-r" : "")}
      opts={{
        align: "start",
        dragFree: true,
      }}
    >
      <div className="w-full carousel-gradient-r carousel-gradient-l overflow-hidden">
        <div className="p-slider z-0">
          <CarouselContent overflowHidden={false}>
          {
            favorites.map((favorite, index) => (
              <CarouselItem key={"node-fav-" + favorite.id} className="basis-4/5 md:basis-2/5 lg:basis-[30%] group">
                  <Renderer favorite={favorite} />
                </CarouselItem>
              ))
            }
          </CarouselContent>
        </div>
      </div>
    </Carousel>
)
}
