import Container from "@/components/ui/container";
import {drupal} from "@/lib/drupal";
import {DrupalNode} from "next-drupal";
import {PodcastsPageProps} from "@/components/podcast/type";
import Link from "next/link";
import {Button} from "@/components/ui/button";
import {NodePodcastSlider} from "@/components/nodeTypes/node--podcast--slider";

const upcoming = ({nodes}: PodcastsPageProps) => {
  const resourcesPromoted = drupal.deserialize(nodes) as DrupalNode[];


  const SeeMoreBtn = () => (
    <Button className="justify-self-center" variant="secondary" asChild>
      <Link href="/podcasts">
        Découvrir nos podcasts
      </Link>
    </Button>
  )

  return (
    <Container>
      <div className="block md:grid grid-cols-6 col-span gap-6">
        <div className="text-left col-span-2 max-md:mb-6">
          <h2 className="text-white text-[28px] mb-4">Nos podcasts à venir</h2>
          <p className="text-white mb-0 md:mb-16">
            Retrouvez toutes les rediffusions de vos émissions préférées sur notre page Podcast.
          </p>
          <div className="my-8 max-md:hidden">
            {SeeMoreBtn()}
          </div>
        </div>
        {resourcesPromoted && resourcesPromoted.length > 0 ? (
          <NodePodcastSlider podcasts={resourcesPromoted}/>
        ) : (
          <p className="text-center my-8">Pas de podcast à venir disponible</p>
        )
        }

        <div className="flex flex-col justify-center md:hidden">
          {SeeMoreBtn()}
        </div>
      </div>
    </Container>
  )
}
export default upcoming
