import Container from "@/components/ui/container";
import {drupal} from "@/lib/drupal";
import {DrupalNode} from "next-drupal";
import {NodeFavoriteSlider} from "@/components/nodeTypes/node--favorites--slider";
import {FavoritesPageProps} from "./type";

const slider = ({nodes}: FavoritesPageProps) => {
  const resourcesFavorites = drupal.deserialize(nodes) as DrupalNode[];

  return (
    <div className="mb-10">
      <Container>
        <h2 className="text-[28px] mb-4 text-black">Coups de coeur RRB</h2>
      </Container>

      {resourcesFavorites && resourcesFavorites.length > 0 ? (
        <NodeFavoriteSlider favorites={resourcesFavorites} />
      ) : (
        <p className="text-center my-8">Pas de coups de coeur disponible</p>
      )
      }
    </div>
  )
}
export default slider
