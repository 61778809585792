import {NodePodcastPromoted} from "@/components/nodeTypes/node--podcast--promoted";
import Link from "next/link";
import {Button} from "@/components/ui/button";
import Container from "@/components/ui/container";
import {drupal} from "@/lib/drupal";
import {DrupalNode} from "next-drupal";
import {PodcastsPageProps} from "@/components/podcast/type";

const latest = ({nodes, seeAllBtn = false, title = 'Nos derniers podcasts'}: PodcastsPageProps) => {

  const resourcesPromoted = drupal.deserialize(nodes) as DrupalNode[];

  return (
    <Container>
      <div className="mb-8 md:mx-0">
        <p className='text-[24px] text-foreground-dark mb-3'>{title}</p>
        {resourcesPromoted && resourcesPromoted.length > 0 ?  (
          <>
            <NodePodcastPromoted podcasts={resourcesPromoted}/>
            {
              seeAllBtn && (
                <div className="mt-8 flex max-md:flex-col justify-center">
                  <Button className="justify-self-center" asChild>
                    <Link href="/podcasts">Voir tous nos podcasts</Link>
                  </Button>
                </div>
              )
            }
          </>
        ) : (
          <p className="text-center my-8">Pas de podcast disponible</p>
        )
        }
      </div>
    </Container>
  )
}

export default latest
