import {DrupalNode} from "next-drupal"
import {formatDatetime} from "lib/utils"
import AdaptiveSeparator from "@/components/ui/adaptive-separator";

interface NodePodcastPromotedProps {
  podcasts: DrupalNode[]
}

export function NodePodcastUpcoming({podcasts}: NodePodcastPromotedProps) {

  const isLast = (index) => {
    return index >= podcasts.length - 1;
  }

  const Renderer = ({podcast, index}) => {
    return (
      <>
        <div key={index} className="w-1/2">
          {podcast.field_thematique && podcast.field_thematique.length > 0 && (
            <p className="text-[14px] text-neutral-600 mb-1">{podcast.field_thematique[0].name}</p>
          )}
          <h1 className="mb-1 text-[20px] text-foreground-dark line-clamp-2">{podcast.title}</h1>

          {podcast.body?.summary && (
            <p><span className="text-[14px] line-clamp-2">{podcast.body?.summary}</span></p>
          )}

          <div className="mt-6">
            <span className="text-[14px] text-sm text-foreground-light uppercase text-neutral-600">{formatDatetime(podcast.created)}</span>
          </div>
        </div>
        {!isLast(index) && <AdaptiveSeparator defaultOrientation="vertical"/>}
      </>
    )
  }

  return (
    <article className="flex col-span gap-6">
      {
        podcasts.map((podcast, index) => (
          <Renderer podcast={podcast} index={index} key={index} />
        ))
      }
    </article>
  )
}
