import React from 'react';
import {Skeleton} from "@/components/ui/skeleton";

const RadioLiveSkeleton = () => {
  return (
    <div className="flex space-x-4">
      <div className="basis-1/3 flex flex-col space-y-4">
        <Skeleton className="h-[350px] w-full"/>
        <div className="space-y-1">
          <Skeleton className="h-[40px] w-[250px]"/>
          <Skeleton className="h-5 w-[200px]"/>
        </div>
      </div>

      <div className="basis-1/4 flex flex-col space-y-4">
        <Skeleton className="h-[350px] w-full"/>
        <div className="space-y-1">
          <Skeleton className="h-[40px] w-[250px]"/>
          <Skeleton className="h-5 w-[200px]"/>
        </div>
      </div>

      <div className="basis-1/4 flex flex-col space-y-4">
        <Skeleton className="h-[350px] w-full"/>
        <div className="space-y-1">
          <Skeleton className="h-[40px] w-[250px]"/>
          <Skeleton className="h-5 w-[200px]"/>
        </div>
      </div>
    </div>
  );
};

export default RadioLiveSkeleton;