import Container from "@/components/ui/container";
import {drupal} from "@/lib/drupal";
import {DrupalNode} from "next-drupal";
import {NodePodcastUpcoming} from "@/components/nodeTypes/node--podcast--upcoming";
import {PodcastsPageProps} from "@/components/podcast/type";

const upcoming = ({nodes}: PodcastsPageProps) => {
  const resourcesPromoted = drupal.deserialize(nodes) as DrupalNode[];

  if (resourcesPromoted.length > 0) {
    return (
      <Container>
        <div className="bg-lightBlue p-6 my-8">
          <p className='text-[32px] text-foreground-dark mb-6'>A ne pas manquer</p>
          {resourcesPromoted && resourcesPromoted.length > 0 ? (
            <NodePodcastUpcoming podcasts={resourcesPromoted}/>
          ) : (
            <p className="text-center my-8">Pas de podcast à venir disponible</p>
          )
          }
        </div>
      </Container>
    )
  }

  else {
    return (
      <></>
    )
  }
}
export default upcoming
