import {DrupalNode} from "next-drupal"
import {Carousel, CarouselContent, CarouselItem} from "@/components/ui/carousel";
import React from "react";
import Image from "next/image";
import Link from "next/link";
import {absoluteUrl} from "@/lib/utils";

interface NodePodcastPromotedProps {
  podcasts: DrupalNode[]
}

export function NodePodcastSlider({podcasts}: NodePodcastPromotedProps) {

  const Renderer = ({podcast}) => {
    return (
      <Link href={podcast.path.alias} className="flex flex-col">
        <div className="h-[350px]">
          <Image className='w-full h-full object-cover'
                 src={absoluteUrl(podcast.field_media_illustration.field_media_image.uri.url)}
                 width='140'
                 height='140'
                 alt={podcast.field_media_illustration.field_media_image.resourceIdObjMeta.alt ? podcast.field_media_illustration.field_media_image.resourceIdObjMeta.alt : ''}
                 priority
          />
        </div>
        <div>
          <h2 className="mb-1 text-[28px] lg:text-[32px] text-white">{podcast.title}</h2>
          {podcast.field_thematique && podcast.field_thematique.length > 0 && (
            <p className="text-[20px] text-white mb-1">{podcast.field_thematique[0].name}</p>
          )}
          <p><span className="text-[14px] text-white">Avec {podcast.field_animateurs[0].title}</span></p>
        </div>
      </Link>
    )
  }

  return (
    <Carousel
      opts={{
        align: "start",
        dragFree: true,
      }}
      className="col-span-4"
    >
      <CarouselContent>
        {
          podcasts.map((podcast, index) => (
            <CarouselItem key={"podcast-slide-" + podcast.id}
                          className="basis-full lg:basis-1/2 xl:basis-1/3 2xl:basis-1/4 group">
              <Renderer podcast={podcast}/>
            </CarouselItem>
          ))
        }
      </CarouselContent>
    </Carousel>
)
}
