import React from 'react';
import Head from "next/head";
import {HeadMetadaProps} from "@/components/headMetadata/type";
import {OpenGraph} from "next/dist/lib/metadata/types/opengraph-types";
import defaultOgImg from "@/public/default-og-img.jpg";

const defaultOpenGraph:OpenGraph = {
  images: defaultOgImg.src
}

const HeadMetadata = ({ openGraph:openGraph = defaultOpenGraph,...props}:HeadMetadaProps) => {

  const {
    children,
    title,
    description,
  } = props;

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      <title>{(typeof title !== "undefined" ? (title + " | ") : "") + "Radio Rythme Bleu"}</title>
      <meta
        property="og:title"
        content={(typeof title !== "undefined" ? (title + " | ") : "") + "Radio Rythme Bleu"}
      />
      {description && (
        <>
          <meta
            name="description"
            content={description}
          />
          <meta
            property="og:description"
            content={description}
          />
        </>
      )}

      {openGraph && openGraph.images && (
        <meta
          property="og:image"
          content={process.env.NEXT_PUBLIC_URL + openGraph.images.toString()}
        />
      )}

      <meta name="twitter:card" content="summary_large_image"/>
      <link rel="preconnect" href={process.env.DRUPAL_BASE_URL_BACKEND}/>
      {children}
      <meta name="google-site-verification" content="rgPWUdspVP6YwB2Cn_F2tMM0WX-MnfZVxawD_XD_2j8"/>
    </Head>
  );
};

export default HeadMetadata;
