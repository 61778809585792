import React, {useState, useEffect, ReactElement} from 'react';
import {Separator} from './separator';

/**
 * Séparateur adaptatif
 * @constructor
 */
const AdaptiveSeparator = ({defaultOrientation}): ReactElement => {
    const [orientation, setOrientation] = useState(defaultOrientation || 'vertical');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                if (defaultOrientation === 'vertical'){
                    setOrientation('horizontal');
                }
                else {
                    setOrientation('vertical');
                }
            } else {
                if (defaultOrientation === 'vertical'){
                    setOrientation('vertical');
                }
                else {
                    setOrientation('horizontal');
                }
            }
        };

        handleResize(); // Appel initial pour définir l'orientation en fonction de la largeur actuelle
        window.addEventListener('resize', handleResize); // Ajoute un écouteur d'événements pour détecter les changements de taille de fenêtre

        return () => {
            window.removeEventListener('resize', handleResize); // Nettoie l'écouteur d'événements lors du démontage du composant
        };
    }, []);

    const separatorClass = orientation === 'vertical' ? 'h-auto' : 'w-auto';

    // @ts-ignore parce que sinon mon IDE m'aime pas
    return <Separator orientation={orientation} className={separatorClass}/>;
}

export default AdaptiveSeparator;
