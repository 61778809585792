import React, {useEffect, useState} from 'react';
import {EquipeSliderProps} from "@/components/equipe/type";
import {drupal} from "@/lib/drupal";
import {DrupalNode} from "next-drupal";
import Container from "@/components/ui/container";
import {Carousel, CarouselContent, CarouselItem} from "@/components/ui/carousel";
import Image from "next/image";
import {absoluteUrl} from "@/lib/utils";

const EquipeSlider = ({nodes}: EquipeSliderProps) => {
  const resourcesEquipe = drupal.deserialize(nodes) as DrupalNode[];
  const [processedResources, setProcessedResources] = useState<DrupalNode[][]>([])
  const [roles, setRoles] = useState<string[]>([])
  const [currentFilter, setCurrentFilter] = useState<string>("all")
  const [currentNodes, setCurrentNodes] = useState<DrupalNode[]>([])

  useEffect(() => {

    let processed: DrupalNode[][] = []
    let allNodes: DrupalNode[] = []
    let localRoles: string[] = []

    resourcesEquipe.map((item) => {
      let weight = 99
      const orderBy = item.field_order_by ?? 99999999
      item.field_roles.map((role) => {
        let masterRole = role

        if(role?.parent[0].name){
          masterRole = role.parent[0]
        }

        if(!localRoles.includes(masterRole.name)){
          localRoles.push(masterRole.name)
        }

        if(masterRole.weight < weight) {
          weight = masterRole.weight
        }

        if(processed[masterRole.name] === undefined){
          processed[masterRole.name] = []
        }

        processed[masterRole.name].push(item)
      })
      item.weight = weight + orderBy
      allNodes.push(item)
    })

    allNodes.sort((a, b) => a.weight - b.weight) // Tri via l'ordre de la taxo drupal
    processed['all'] = allNodes
    setProcessedResources(processed)
    setCurrentNodes(allNodes)
    setRoles(localRoles)
  }, []);

  useEffect(() => {
    if (processedResources[currentFilter]) {
      processedResources[currentFilter].sort((a, b) => a.weight - b.weight)
      setCurrentNodes(processedResources[currentFilter])
    }
  }, [currentFilter, processedResources]);

  const Renderer = ({item}) => {
    return (
      <div className="relative">
        <div>
          <Image className="w-full aspect-[55/82] object-cover"
                 src={absoluteUrl(item.field_image.uri.url)}
                 width="275"
                 height="410"
                 alt={item.field_image.resourceIdObjMeta.alt ? item.field_image.resourceIdObjMeta.alt : ''}
          />
        </div>
        <div className="absolute p-4 w-full bottom-0 bg-gradient-to-t from-black/80 to-black/0">
          <h2 className="text-[20px] text-white">{item.title}</h2>
          <span className="text-xs text-neutral-200 mb-1">
          {
            item.field_roles && item.field_roles.map((role, index) => (
              <span key={"node-team-role-" + role.id}>{index !== 0 && ", "} {role.name}</span>
            ))
          }
          </span>
        </div>
      </div>
    )
  }

  const renderRolesFilter = () => {
    return (
      <>
        <div className="flex gap-6 mb-8 overflow-auto">
          <button className={"btn-filter" + (currentFilter === "all" ? " active" : "")} onClick={() => setCurrentFilter('all')}>Tous</button>
          {roles.map((item, index) => (
            <button className={"btn-filter" + (currentFilter === item ? " active" : "")} key={index} onClick={() => setCurrentFilter(item)}>
              {item}
            </button>
          ))}
        </div>
      </>
    )
  }

  return (
    <div className="mb-10">
      <Container>
        <h2 className="text-[28px] mb-4 text-black">L’équipe RRB</h2>
        {renderRolesFilter()}
      </Container>

      {currentNodes && currentNodes.length > 0 ? (
        <Carousel
          opts={{
            align: "start",
            dragFree: true,
          }}
        >
          <div className="w-full carousel-gradient-r carousel-gradient-l overflow-hidden">
            <div className="p-slider z-0">
              <CarouselContent overflowHidden={false}>
                {
                  currentNodes.map((item, index) => (
                    <CarouselItem key={"node-team-" + item.id} className="basis-4/5 md:basis-1/3 lg:basis-1/4 group">
                      <Renderer item={item} />
                    </CarouselItem>
                  ))
                }
              </CarouselContent>
            </div>
          </div>
        </Carousel>
      ) : (
        <p className="text-center my-8">Pas d&apos;équipe disponible</p>
      )
      }
    </div>
  );
};

export default EquipeSlider;
